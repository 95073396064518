import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./intercepter";
import React , { Suspense,useContext  } from 'react'
import Loader from './components/Includes/Loader';
import { AuthContext } from './components/Routes/AuthContext';
import LoadRoute from './components/Routes/LoadRoute';


export default function App() {

const { user} = useContext(AuthContext)
  return (
    <Suspense fallback={<div className="container"><Loader isLoading={true}/></div>}>
        <LoadRoute type = {user?.user_type} role = {user?.role}/>
    </Suspense>
  );
 
}

