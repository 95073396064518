import React from "react";
import { Oval,LineWave } from 'react-loader-spinner';

const Loader = ({isLoading})=>{
    return(
        <>
        <Oval
            height="50"
            width="50"
            color="#c56683"
            ariaLabel="line-wave"
            wrapperStyle={{ position:'fixed', zIndex: '1060',width:'100%',height:'100%', alignItems:'center', top:'0%',left:'0',justifyContent: 'center',background:'rgba(255, 255, 255, .8)'}}
            wrapperClass=""
            data-testid="loader"
            visible={isLoading}
        >
         <div className="loader-message">Loading...</div>
        </Oval>
        </>
    )
} 

const ContentLoader = ({isLoading})=>{
    return(
        <>
        <LineWave
            height="50"
            width="50"
            color="#c56683"
            ariaLabel="line-wave"
            wrapperStyle={{ position:'fixed', zIndex:'999',width:'100%',height:'100%', alignItems:'center', top:'0%',left:'0',justifyContent: 'center',background:'rgba(0, 0, 0, 0)'}}
            wrapperClass=""
            data-testid="loader"
            visible={isLoading}
        >
            <div className="loader-message">Loading...</div>
        </LineWave>

        </>
    )
}

export { ContentLoader}
export default Loader;
